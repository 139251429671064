import React from 'react';
import { CFormGroup, CLabel, CInput, CFormText } from '@coreui/react';
import PropTypes from 'prop-types';

const Input = (props) => {
    const {
        type = null,
        step = null,
        name,
        placeholder,
        title,
        value,
        onChange,
        onBlur = null,
        // onKeyDown,
        isDisabled,
        errorMessage,
    } = props;

    const handleBlur = () => {};

    return (
        <CFormGroup>
            {!title ? null : <CLabel htmlFor={name}>{title}</CLabel>}
            <CInput
                id={name}
                type={type || 'text'}
                step={step || 'any'}
                name={name}
                placeholder={placeholder || 'Enter...'}
                value={value}
                min="0"
                onChange={onChange}
                onBlur={!onBlur ? handleBlur : onBlur}
                // onKeyDown={onKeyDown}
                disabled={isDisabled}
            />
            {!errorMessage ? null : <CFormText color="danger">{errorMessage}</CFormText>}
        </CFormGroup>
    );
};

Input.propTypes = {
    type: PropTypes.string,
    step: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    // onKeyDown: PropTypes.func,
    isDisabled: PropTypes.bool,
    errorMessage: PropTypes.string,
};

export default Input;
