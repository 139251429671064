import React from 'react';
import { CModal, CModalHeader, CModalBody, CModalFooter } from '@coreui/react';
import PropTypes from 'prop-types';

const Modal = (props) => {
    const { isOpen, toggle, title, children: body, footer = null } = props;

    return (
        <CModal size="xl" show={isOpen} onClose={toggle} backdrop={true} closeOnBackdrop={false}>
            <CModalHeader closeButton>{title}</CModalHeader>
            <CModalBody>{body}</CModalBody>
            {footer && <CModalFooter>{footer}</CModalFooter>}
        </CModal>
    );
};

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    footer: PropTypes.node,
};

export default Modal;
