import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import './scss/style.scss';
import { Loading } from './components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./pages/Login'));
const ResetPassword = React.lazy(() => import('./pages/ResetPassword'));

class App extends Component {
    render() {
        return (
            <HashRouter>
                <React.Suspense fallback={loading}>
                    <ToastContainer autoClose={5000} />
                    {!this.props.isFinished && <Loading />}
                    <Switch>
                        <Route exact path="/login" name="Login" render={(props) => <Login {...props} />} />
                        <Route
                            exact
                            path="/reset-password"
                            name="Reset Password"
                            render={(props) => <ResetPassword {...props} />}
                        />
                        <Route path="/" name="Home" render={(props) => <TheLayout {...props} />} />
                    </Switch>
                </React.Suspense>
            </HashRouter>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.progress,
    };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(App);
