import React from 'react';
import { CAlert } from '@coreui/react';
import PropTypes from 'prop-types';

const Alert = (props) => {
  const { message, color } = props;

  return <CAlert color={!color ? 'danger' : color}>{message}</CAlert>;
};

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default Alert;
