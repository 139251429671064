import _ from 'lodash';

// Regex
export const passwordRegex = /^[\w\d\W]{6,32}$/;

export const colors = {
    success: 'success',
    primary: 'primary',
    info: 'info',
    warning: 'warning',
    danger: 'danger',
};

export const getGenericBadgeColorText = (text) => {
    switch (_.lowerCase(text)) {
        // Status type 1
        case 'active':
            return colors.success;

        case 'inactive':
            return colors.danger;

        // Status type 2
        case 'approved':
            return colors.success;

        case 'pending':
            return colors.warning;

        case 'banned':
            return colors.danger;

        case 'canceled':
            return colors.danger;

        // Priority type
        case 'high':
            return colors.danger;

        case 'medium':
            return colors.warning;

        case 'low':
            return colors.info;

        // Extra type
        // todo: For extra type badge colors

        default:
            return colors.primary;
    }
};

export const getRoleOptions = () => [
    { value: 'super-admin', label: 'Super Admin' },
    { value: 'admin', label: 'Admin' },
    { value: 'manager', label: 'Manager' },
    { value: 'operator', label: 'Operator' },
];

export const getUserStatusOptions = () => [
    { value: 'active', label: 'Active' },
    { value: 'pending', label: 'Pending' },
    { value: 'banned', label: 'Banned' },
];

export const getGenericStatusOptions = () => [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
];

export const getFileTypeOptions = () => [
    { value: 'unspecified', label: 'Others' },
    { value: 'image', label: 'Image' },
    { value: 'video', label: 'Video' },
    { value: 'document', label: 'Document' },
    { value: 'gif', label: 'GIF' },
];

export const getLinkTypeOptions = () => [
    { value: 'global', label: 'Events' },
    // { value: 'national', label: 'News' },
];

/*
'pending',
'in-progress',
'in-review',
'accepted',
'rejected'
*/

export const getApplicationStatusOptions = () => [
    { value: 'pending', label: 'Pending' },
    { value: 'in-progress', label: 'Interview In-Progress' },
    { value: 'in-review', label: 'Decision In-Review' },
    { value: 'accepted', label: 'Accepted' },
    { value: 'rejected', label: 'Rejected' },
];

export const getFormData = (payload) => {
    const formData = new FormData();

    for (let key in payload) {
        // console.debug(key, payload[key], payload[key].name);

        if (_.isUndefined(payload[key]) || _.isNull(payload[key])) continue;

        if (payload[key] instanceof File) {
            formData.append(key, payload[key], payload[key].name, payload[key].type, payload[key].size);
        } else {
            formData.append(key, payload[key]);
        }
    }

    // console.debug('formData', formData);

    return formData;
};

export const isValidHttpUrl = (text) => {
    let url;

    try {
        url = new URL(text);
    } catch (_) {
        return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
};

export const getBadge = (status) => {
    switch (status) {
        case 'active':
            return 'success';

        case 'inactive':
            return 'secondary';
    }
};

// export const pad = (num, size) => {
//     num = num.toString();
//     while (num.length < size) num = '0' + num;
//     return num;
// };
