import React from 'react';
import ReactLoading from 'react-loading';

const Loading = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        width: '100%',
        height: '100%',
        zIndex: '1111',
        background: 'rgba(255, 255, 255, 0.3)',
        backdropFilter: 'blur(5px)',
      }}
    >
      <ReactLoading type={`spin`} color={`#3c4b64`} height={50} width={50} />
    </div>
  );
};

export default Loading;
