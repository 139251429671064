import React, { useMemo } from 'react';
import { CDataTable, CBadge, CButton, CCol, CRow, CCard, CCardBody } from '@coreui/react';
import { getGenericBadgeColorText } from '../../utils';
import _ from 'lodash';

export default function DataTable({
    children,
    data,
    ignoredColumns = [],
    actionIdentifier = null,
    actions = null,
    title = null,
    subtitle = null,
    addNewItemCallback = null,
    scopedSlots = null,
    // filtration = null,
    // pagination = null,
}) {
    let columnHeads = [];
    // let rows = [];

    // console.debug({ actions, addNewItemCallback });

    if (!_.isUndefined(data) && !_.isNull(data) && _.size(data) > 0) {
        const keys = _.keys(_.omit(data[0], ignoredColumns));

        columnHeads = _.map(keys, (key) => ({
            key,
            label: _.upperCase(key),
        }));

        if (!_.isNull(actions) && _.size(actions) > 0) {
            columnHeads.push({
                key: 'actions',
                label: 'ACTIONS',
                _style: { width: '5%' },
            });
        }

        /* todo: We do not require rows block for this theme
        rows = _.map(data, (datum) => {
            if (!_.isNull(actions) && _.size(actions) > 0) {
                datum['action-buttons'] = null;
            }

            const columns = _.reduce(
                _.omit(datum, ignoredColumns),
                (result, value, key) => {
                    // Data object
                    const temp = result;

                    if (!_.isEqual(key, 'action-buttons')) {
                        temp.push({
                            key,
                            value,
                            isBadge: _.lowerCase(key) === 'status' || _.lowerCase(key) === 'priority',
                        });
                    } else {
                        // todo: Setup action buttons here
                        temp.push({ key, value: '', isBadge: false });
                    }

                    return temp;
                },
                []
            );

            return {
                columns,
                identity: datum[actionIdentifier],
            };
        });
        */
    }

    // console.debug({ columnHeads });
    // console.debug({ rows });

    // UI Setup

    const fields = useMemo(() => columnHeads, [columnHeads]);
    const items = useMemo(() => data, [columnHeads]);

    // console.debug({ fields, items });

    return (
        <CCard>
            <CCardBody>
                {!title ? null : <h3 className="mb-3">{title}</h3>}
                {!subtitle ? null : <p>{subtitle}</p>}
                {!addNewItemCallback ? null : (
                    <CButton
                        className="mb-3"
                        color="primary"
                        onClick={(e) => {
                            e.preventDefault();

                            if (addNewItemCallback) addNewItemCallback();
                        }}
                    >
                        Add
                    </CButton>
                )}

                <CRow>
                    <CCol xl="12">
                        {useMemo(
                            () => (
                                <CDataTable
                                    scopedSlots={{
                                        status: (item) => {
                                            // console.debug({ item });

                                            return (
                                                <td>
                                                    <CBadge color={getGenericBadgeColorText(item.status)}>
                                                        {item.status}
                                                    </CBadge>
                                                </td>
                                            );
                                        },

                                        actions: (item) => {
                                            // console.debug({ item });

                                            return (
                                                <td>
                                                    {!_.isNull(actions) &&
                                                        _.size(actions) > 0 &&
                                                        _.map(actions, (action, index) => (
                                                            <CButton
                                                                key={index}
                                                                className="btn-block"
                                                                variant="outline"
                                                                color={action.color}
                                                                size="sm"
                                                                onClick={(e) => {
                                                                    e.preventDefault();

                                                                    // console.debug({ actionIdentifier });

                                                                    if (actionIdentifier && action.callback)
                                                                        action.callback(item[actionIdentifier]);
                                                                }}
                                                            >
                                                                {action.text}
                                                            </CButton>
                                                        ))}
                                                </td>
                                            );
                                        },

                                        ...scopedSlots,
                                    }}
                                    fields={fields}
                                    items={data}
                                    sorter
                                    // clickableRows
                                    columnFilter
                                    hover
                                    border
                                    outlined
                                    // onRowClick={(item) => {
                                    //     // console.debug({ item });
                                    // }}
                                />
                            ),
                            [items, fields]
                        )}
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    );
}
