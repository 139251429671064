import React, { useState, useRef, useCallback, useMemo } from 'react';
import { CFormGroup, CLabel, CFormText, CButton } from '@coreui/react';
import { Editor } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';

const RichTextBox = (props) => {
    const { name, placeholder, title, value, setFieldValue, setFieldTouched, errorMessage } = props;

    const [isTouched, setTouched] = useState(false);

    const editorRef = useRef(null);

    const handleSaveChanges = useCallback((e) => {
        e.preventDefault();

        if (editorRef.current) {
            // console.log(editorRef.current.getContent());

            const wrappedContent = JSON.stringify(editorRef.current.getContent());

            setFieldValue(name, wrappedContent);
            setFieldTouched(name, true);

            setTouched(false);
        }
    }, []);

    // console.debug({ value });

    let wrappedValue = value;

    try {
        JSON.parse(wrappedValue);
    } catch (error) {
        console.error(error);

        wrappedValue = null;
    }

    // console.debug({ wrappedValue });

    return (
        <CFormGroup>
            {!placeholder ? null : (
                <p
                    style={{
                        color: 'grey',
                        margin: 0,
                        padding: 0,
                    }}
                >
                    {placeholder}
                </p>
            )}
            {!title ? null : <CLabel htmlFor={name}>{title} Editor</CLabel>}
            {useMemo(
                () => (
                    <Editor
                        id={name}
                        apiKey="0tmys8zuuq4r8xof38r6vokenwx39p0dr8gxtqxj0gwt7n2r"
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        initialValue={JSON.parse(wrappedValue)}
                        onEditorChange={(editor) => {
                            // console.debug({ editor });

                            setTouched(true);
                        }}
                        init={{
                            height: 'auto',
                            menubar: 'insert | edit | table',
                            plugins: [
                                'advlist',
                                'autolink',
                                'autoresize',
                                'help',
                                'image',
                                'insertdatetime',
                                'link',
                                'lists',
                                'media',
                                'searchreplace',
                                'table',
                                'wordcount',
                            ],
                            toolbar:
                                'undo redo | blocks | ' +
                                'bold italic forecolor | alignleft aligncenter alignright alignjustify | ' +
                                'bullist numlist | outdent indent | ' +
                                'image media | insertdatetime | searchreplace | removeformat | help | wordcount',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        }}
                    />
                ),
                [wrappedValue]
            )}
            {!isTouched ? null : (
                <CButton
                    className="btn-block mt-3"
                    color={!isTouched ? 'primary' : 'danger'}
                    size="sm"
                    onClick={handleSaveChanges}
                >
                    {!isTouched ? '' : 'Save Your New Changes'}
                </CButton>
            )}
            {!title ? null : <CLabel className="mt-3">{title} Preview</CLabel>}
            <div
                style={{
                    padding: '11px',
                    border: '2px solid #eee',
                    borderRadius: '10px',
                    overflow: 'scroll',
                    position: 'relative',
                }}
                dangerouslySetInnerHTML={{ __html: JSON.parse(wrappedValue) }}
            ></div>
            {!errorMessage ? null : <CFormText color="danger">{errorMessage}</CFormText>}
        </CFormGroup>
    );
};

RichTextBox.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
};

export default RichTextBox;
