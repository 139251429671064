import moment from 'moment-timezone';

export const DATE_FORMAT = {
  DATETIME_GENERAL_TABLE: 'YYYY, MMMM Do, h:mm:ss a',
  DATE_REPORT: 'DD-MMMM-YYYY',
  YEAR_MM: 'YYYY-MM',
  DATETIME_FILE_NAME: 'YYYY-MM-DD-HH-mm-ss',
  YEAR_MM_DD: 'YYYY-MM-DD',
  DATETIME_INPUT_HTML: 'YYYY-MM-DDTHH:mm:ss',
  DATETIME_SERVER: 'YYYY-MM-DDTHH:mm:ssZ',
};

export const getFormattedMoment = (serverDatetime, format) => {
  return moment(serverDatetime).tz('Asia/Dhaka').format(format);
};

export const getFormattedDateForForm = (serverDatetime) => {
  return moment(serverDatetime).tz('Asia/Dhaka').format(DATE_FORMAT.YEAR_MM_DD);
};

export const getFormattedDatetimeForReport = (serverDatetime) => {
  return moment(serverDatetime)
    .tz('Asia/Dhaka')
    .format(DATE_FORMAT.DATE_REPORT);
};

export const getFormattedDatetimeForGeneralTable = (serverDatetime) => {
  return moment(serverDatetime)
    .tz('Asia/Dhaka')
    .format(DATE_FORMAT.DATETIME_GENERAL_TABLE);
};

export const getCurrentDate = () =>
  moment().tz('Asia/Dhaka').format(DATE_FORMAT.DATE_REPORT);

export const getCurrentDatetime = (format) =>
  moment().tz('Asia/Dhaka').format(format);

export const getCurrentYearMonth = () =>
  moment().tz('Asia/Dhaka').format(DATE_FORMAT.YEAR_MM);

export const convertDatetime = (datetime, inFormat) =>
  moment(datetime, inFormat).tz('Asia/Dhaka').format(DATE_FORMAT.DATE_REPORT);

export const isDateExpired = (date) => {
  var dateToCompare = moment(date);
  var now = moment();

  if (now > dateToCompare) {
    return true;
  } else {
    return false;
  }
};

export const isDateLess = (then, now) => {
  var before = moment(then);
  var after = moment(now);

  if (after < before) return true;

  return false;
};

export const getYesterday = () => {
  const date = new Date();
  date.setDate(date.getDate() - 1);

  return date.toISOString();
};

export const getOneMonthBefore = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);

  return date.toISOString();
};

export const getDatetimeConverted = (datetime, inFormat, outFormat) =>
  moment(datetime, inFormat).tz('Asia/Dhaka').format(outFormat);
