import React from 'react';
import { Form } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import _ from 'lodash';

const customStyles = {
    control: (base) => ({
        ...base,
        minHeight: '40px',
    }),
};

const customTheme = (theme) => ({
    ...theme,
    borderRadius: '0.29rem',
    borderWidth: 1,
    colors: {
        ...theme.colors,
        primary25: 'rgba(60,68,177,0.15)',
        primary50: 'rgba(60,68,177,0.15)',
        primary: '#3c44b1',
    },
});

export default function SelectAsyncComponent({
    name,
    placeholder,
    title = null,
    value,
    defaultOptions,
    loadOptions,
    setFieldValue,
    setFieldTouched,
    isMulti,
    isSearchable,
    isClearable,
    isDisabled,
    errorMessage,
}) {
    const selectedValue =
        !defaultOptions || isMulti ? (!value ? null : value) : defaultOptions.find((o) => o.value === value);

    return (
        <Form.Group>
            {!title ? null : <Form.Label htmlFor={name}>{title}</Form.Label>}
            <AsyncSelect
                name={name}
                placeholder={placeholder || 'Select...'}
                value={selectedValue}
                defaultOptions={defaultOptions || []}
                loadOptions={_.debounce(loadOptions, 1000, { leading: false, trailing: true })}
                onChange={(option) => {
                    // console.debug({ option });

                    !isMulti
                        ? setFieldValue(name, !option ? null : option.value, !option ? null : option.label)
                        : setFieldValue(name, option);
                }}
                onBlur={(e) => {
                    e.preventDefault();

                    if (setFieldTouched) setFieldTouched(name, true);
                }}
                onFocus={(e) => {
                    e.preventDefault();

                    if (setFieldTouched) setFieldTouched(name, true);
                }}
                isMulti={isMulti}
                isSearchable={isSearchable || false}
                isClearable={isClearable || false}
                isDisabled={isDisabled || false}
                scrollable={true}
                styles={customStyles}
                theme={customTheme}
                maxMenuHeight={100}
            />
            <Form.Text style={{ color: '#E55353' }} color="danger">
                {errorMessage}
            </Form.Text>
        </Form.Group>
    );
}
