import React from 'react';
import { Form } from 'react-bootstrap';

import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

const RadioWrapper = styled.div`
    display: flex;
    max-height: 24px !important;
    align-items: baseline;
`;

const RadioInput = styled.input`
    margin-right: 12px;
`;

const RadioGroup = (props) => {
    const { id, name, label, message, value, options, setFieldValue } = props;

    const setSelectedOption = (val) => {
        setFieldValue(name, val);
    };

    return (
        <Form.Group>
            {!label ? null : <Form.Label htmlFor={id}>{label}</Form.Label>}

            {options.map(function (option, index) {
                return (
                    <RadioWrapper>
                        <RadioInput
                            key={index}
                            type="radio"
                            value={option.value}
                            checked={value === option.value}
                            onChange={() => setSelectedOption(option.value)}
                        />
                        <p>{option.label}</p>
                    </RadioWrapper>
                );
            })}

            {!message ? null : <Form.Text style={{ color: '#E55353' }}>{message}</Form.Text>}
        </Form.Group>
    );
};

RadioGroup.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    message: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    options: PropTypes.array.isRequired,
    setFieldValue: PropTypes.func.isRequired,
};

export default RadioGroup;
