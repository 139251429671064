import React from 'react';
import { CFormGroup, CFormText, CLabel } from '@coreui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

const CheckboxGroupWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 8px 0px;
`;

const Checkbox = styled.input`
    margin: 0px 16px;
`;

const CheckboxGroup = ({ name, title, selectedValues, options, onSelect, onDeselect, isDisabled, errorMessage }) => {
    console.debug({ options });

    const handleChange = (option) => {
        if (!_.includes(selectedValues, option.value)) onSelect(name, option);
        else onDeselect(name, option);
    };

    return (
        <CFormGroup>
            {!title ? null : <CLabel>{title}</CLabel>}
            <CheckboxGroupWrapper>
                {_.map(options, (option, index) => (
                    <CheckboxWrapper key={index} id={`checkbox-group-${name}-${index}`}>
                        <Checkbox
                            key={index}
                            id={`checkbox-${name}-${index}`}
                            type="checkbox"
                            checked={_.includes(selectedValues, option.value)}
                            onChange={() => handleChange(option)}
                            disabled={isDisabled}
                        />
                        <span>{option.label}</span>
                    </CheckboxWrapper>
                ))}
            </CheckboxGroupWrapper>

            {!errorMessage ? null : <CFormText color="danger">{errorMessage}</CFormText>}
        </CFormGroup>
    );
};

CheckboxGroup.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    selectedValues: PropTypes.array,
    options: PropTypes.array.isRequired,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
    isDisabled: PropTypes.bool,
    errorMessage: PropTypes.string,
};

export default CheckboxGroup;
