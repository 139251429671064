import { store } from '../index';

export const START = 'START';
export const INCREMENT = 'INCREMENT';
export const FINISH = 'FINISH';

export const start = () => {
  store.dispatch({
    type: START,
    payload: 0.0,
  });
};

export const increment = (percent) => {
  store.dispatch({
    type: INCREMENT,
    payload: percent / 100,
  });
};

export const finish = () => {
  store.dispatch({
    type: FINISH,
    payload: 1.0,
  });
};
