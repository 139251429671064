import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

const Input = (props) => {
    const { name, placeholder, title, formValues, setFieldValue, setFieldTouched, errorMessage } = props;

    const getAllowedExtensions = useCallback(() => {
        if (formValues.type === 'image') return ['.png', '.jpg', '.jpeg', '.raw'];
        else if (formValues.type === 'video') return ['.mp4', '.avi', '.mov', '.webm'];
        else if (formValues.type === 'document')
            return ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.txt', '.html'];
        else if (formValues.type === 'gif') return ['.gif'];
        else return [];
    }, [formValues.type]);

    return (
        <Form.Group>
            {!title ? null : <Form.Label htmlFor={name}>{title}</Form.Label>}
            <Form.File
                id={name}
                name={name}
                label={placeholder}
                accept={getAllowedExtensions()}
                onChange={(e) => {
                    e.preventDefault();

                    setFieldValue(name, e.currentTarget.files[0]);
                }}
                onBlur={(e) => {
                    e.preventDefault();

                    if (setFieldTouched) setFieldTouched(name, true);
                }}
                onFocus={(e) => {
                    e.preventDefault();

                    if (setFieldTouched) setFieldTouched(name, true);
                }}
            />
            {!errorMessage ? null : <Form.Text style={{ color: '#E55353' }}>{errorMessage}</Form.Text>}
        </Form.Group>
    );
};

Input.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    title: PropTypes.string,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
};

export default Input;
