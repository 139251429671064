import { START, INCREMENT, FINISH } from '../actions/';

const initialState = { isFinished: true, percent: 0 };

export default (state = initialState, action) => {
  switch (action.type) {
    case START:
      return {
        isFinished: false,
        percent: action.payload,
      };
    case INCREMENT:
      return {
        isFinished: false,
        percent: state.percent + action.payload,
      };
    case FINISH:
      return {
        isFinished: true,
        percent: action.payload,
      };
    default:
      return state;
  }
};
